<template>
    <div class="videoMainBase">
        <h2>設定</h2>
        <p>設定画面</p>
        <table class="normal w100">
            <tbody>
                <tr>
                    <th>バージョン</th>
                    <td>v{{ gitVersion }}</td>
                </tr>
                <tr>
                    <th>再起動</th>
                    <td><a class="btn" @click="reboot">再起動</a></td>
                </tr>
                <tr>
                    <th>更新確認</th>
                    <td><a class="btn" @click="checkUpdate">確認</a></td>
                </tr>
                <tr>
                    <th>動画情報更新</th>
                    <td><a class="btn" @click="update">更新</a></td>
                </tr>
            </tbody>
        </table>

        <br />
        <h2>カテゴリ登録</h2>
        <table class="normal w100" v-if="tags">
            <tbody>
                <tr>
                    <th :rowspan="Object.keys(tags).length + 1">タグ一覧</th>
                </tr>
                <tr v-for="tag, index in tags" :key="index">
                    <td>{{ tag }}</td>
                    <td v-if="!defaultCat.includes(index)" class="tagsTableDel"><img title="削除" @click="delTags(index)"
                            src="../../assets/del.png" /></td>
                    <td v-else></td>
                </tr>
                <tr>
                    <th>登録</th>
                    <td><input type="text" v-model="tagName" class="inpt" /></td>
                    <td><a class="btn" @click="registTag">登録</a></td>
                </tr>
            </tbody>
        </table>

        <br />
        <h2>ログ監査</h2>
        <div class="logViewer">
            <div class="label">
                <div class="fileList">
                    <p>ファイル一覧 </p><img @click="getFiles" class="reloadBtn" src="../../assets/reload.png" />
                </div>
                <div class="fileContent">
                    <p>内容 </p><img @click="getContent" class="reloadBtn" src="../../assets/reload.png" />
                </div>
            </div>
            <div class="content">
                <div class="fileList">
                    <div class="fdir" v-for="(f, index) in files" :key="index">
                        <details>
                            <summary>{{ index }}</summary>
                            <div class="fnameRow" :class="{ selected: `${index}/${fname}` == selected }"
                                v-for="fname in f" :key="fname" @click="selectRow(`${index}/${fname}`)">{{ fname }}
                            </div>
                        </details>
                    </div>
                </div>
                <div class="fileContent" v-html="context"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

export default {
    setup() {
        const store = useStore();
        const files = ref([]);
        const context = ref("");
        const defaultCat = ["sclSwim", "bigboob", "loli", "nohair", "nocover", "swim", "threeup", "cos", "foreiner", "JK"];
        const selected = ref("");
        const tagName = ref("");
        const gitVersion = ref("未取得");
        const tags = ref([]);

        const load = async () => {
            getFiles();
            tags.value = await store.dispatch('Video/call', { url: "videos/getTags" });
            gitVersion.value = await store.dispatch('Video/call', { url: "videos/getVersion" });
        }

        const getFiles = async () => {
            files.value = await store.dispatch('Video/call', { url: "videos/logfiles" });
        }

        const selectRow = (path) => {
            selected.value = path;
            getContent();
        }

        const getContent = async () => {
            if (!selected.value) return;
            context.value = await store.dispatch('Video/call', { url: "videos/logContents", data: { fname: selected.value } });
        }

        function htmlspecialchars(str) {
            return (str + '').replace(/&/g, '&amp;')
                .replace(/"/g, '&quot;')
                .replace(/'/g, '&#039;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;');
        }

        const checkUpdate = async () => {
            const resp = await store.dispatch('Video/call', { url: "videos/checkUpdate" });
            if(resp.result) {
                // モーダルで更新確認
                if(await store.dispatch('Common/openConfirm', { title: "更新確認", text: `更新があります。更新しますか？<br />${resp.obj}` })) {
                    // 更新処理
                    await store.dispatch('Video/call', { url: "videos/update" });
                    await store.dispatch('Common/showModal', { text: "更新しました。サーバーが立ち上がるまでしばらくお待ち下さい" });
                }
            }else {
                await store.dispatch('Common/showModal', { text: "更新はありませんでした。" });
            }
        }

        const contextdata = computed(() => {
            let data = context.value;

            let escaped = htmlspecialchars(data).replace(/\t/g, '&#009;').replace(/ /g, '&nbsp;');

            let rsp = "";
            for (let row of escaped.split('\n')) {
                let split = row.split("&nbsp;");

                //decorate
                if (1 < split.length) {
                    split[0] = split[0].replace('[', `[<span class="logTimeText">`).replace(']', '</span>]');
                }

                // console.log(split);

                rsp += `<p>${split.join('&nbsp;')}</p>`;
            }

            rsp = rsp.replace(/\[TRACE\]/g, `[<span class="traceText">TRACE</span>]`)
                .replace(/\[DEBUG\]/g, `[<span class="debugText">DEBUG</span>]`)
                .replace(/\[INFO\]/g, `[<span class="infoText">INFO</span>]`)
                .replace(/\[WARNING\]/g, `[<span class="warnText">WARNING</span>]`)
                .replace(/\[ERROR\]/g, `[<span class="errorText">ERROR</span>]`)
                .replace(/\[FATAL\]/g, `[<span class="fatalText">FATAL</span>]`);

            return rsp.replace(/\r|\n/g, "");
        })

        const reboot = async () => {
            await store.dispatch('Video/call', { url: "videos/restart" });
        }

        const update = async () => {
            selected.value = "video/video.log";
            let resp = await store.dispatch('Video/call', { url: "videos/updatevideos" });

            if (resp) await store.dispatch('Common/showModal', { text: "更新しました" });
            getContent();
        }

        load();

        const registTag = async () => {
            if (!tagName.value) {
                await store.dispatch('Common/showModal', { text: "タグ名を入力してください" });
            }

            if (await store.dispatch('Video/call', {
                url: "videos/registCategories", data: {
                    name: tagName.value
                }
            })) {
                await store.dispatch('Common/showModal', { text: "登録しました" });
            } else {
                await store.dispatch('Common/showModal', { text: "登録に失敗しました" });
            }
            tags.value = await store.dispatch('Video/call', { url: "videos/getTags" });
        }

        const delTags = async (key) => {
            if (!key) return;

            if (await store.dispatch('Common/openConfirm', { title: "削除確認", text: "削除すると戻せません。よろしいですか？" })) {
                if (await store.dispatch('Video/call', { url: "videos/deleteCategories", data: { value: key } })) {
                    await store.dispatch('Common/showModal', { text: "削除しました" });
                } else {
                    await store.dispatch('Common/showModal', { text: "削除に失敗しました" });
                }
            }
            tags.value = await store.dispatch('Video/call', { url: "videos/getTags" });
        }

        return {
            files,
            context: contextdata,
            selectRow,
            getFiles,
            gitVersion,
            getContent,
            update,
            reboot,
            tags,
            defaultCat,
            registTag,
            delTags,
            tagName,
            checkUpdate
        }
    }
}
</script>

<style scoped>
.tagsTableDel img {
    width: 2em;
    cursor: pointer;
}
</style>